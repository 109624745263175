angular.module('angular.drag.resize', [])
  .provider('adrConfig', function adrConfigProvider(){
    //defaults
    var defaultConfig = {
      iconPosition: [0, 0],
      mode: 'all',
      modes: ['all', 'horizontal', 'vertical'],
      options: {
        mode: 'all',
        invert: false,
        minWidth: 0,
        maxWidth: Number.MAX_SAFE_INTEGER,
        minHeight: 0,
        maHeight: Number.MAX_SAFE_INTEGER,
        borderColor: 'transparent',
        disabled: false
      }
    };
    var config = angular.extend({}, defaultConfig);
    this.$get = [function(){
      return {
        iconPosition: config.iconPosition,
        mode: config.mode,
        modes: config.modes,
        options: config.options
      };
    }];
  })
  .directive('resize', ['adrConfig', '$document', function(adrConfig, $document) {
    return {
        restrict: 'A',
        scope: {
          options: '=resize',
          onChange: '&onResizeEnd',
          onResize: '&onResize'
        },
        link: function(scope, element, attr) {
          let dimension = {};
          let iconPosition = adrConfig.iconPosition;
          let position = {};

          let options = (scope.options === undefined) ? adrConfig.options : {...adrConfig.options, ...scope.options};

          scope.$watch('options', function(newValue, oldValue) {
            if (newValue){

              options = newValue;
              const actualWidth = element.prop('offsetWidth');
              const validWidth = getValidWidth(actualWidth);
              const actualHeight = element.prop('offsetHeight');
              const validHeight = getValidHeight(actualHeight);

              if (mode == 'horizontal' && validWidth !== actualWidth){
                const newDimensions = {
                  width: validWidth + 'px'
                };
  
                element.css(newDimensions);
              } 
              else if (mode == 'vertical' && validHeight !== actualHeight){
                const newDimensions = {
                  height: validHeight + 'px'
                };
  
                element.css(newDimensions);
              }
              else if(mode == 'all' && (validWidth !== actualWidth || validHeight !== actualHeight)) {
                element.css({
                  width: validWidth + 'px',
                  height: actualHeight + 'px'
                })
              }

              btn.style.visibility = options.disabled ? 'hidden' : '';
            }  
          }, true);

          if (options.initWidth)
            element.css({ width: options.initWidth + 'px'});

          if (options.initHeight)
            element.css({ height: options.initHeight + 'px'});
          
          var mode = adrConfig.modes.indexOf(options.mode) ? options.mode : adrConfig.options.mode;

          //create button for resizing
          var btn = document.createElement("span");
          btn.style.position = 'absolute';

          if(mode == 'horizontal') {
            btn.className = 'horizontal' + (options.invert ? ' invert' : ' normal') + ' resize-handler';
            
            btn.innerHTML = "\
            <svg width='4' height='22' \
              style='display:block; margin:auto;'>\
              <g fill='#42526E' fill-rule='evenodd'>\
                <circle cx='2' cy='2' r='1'/>\
                <circle cx='2' cy='8' r='1'/>\
                <circle cx='2' cy='14' r='1'/>\
                <circle cx='2' cy='20' r='1'/>\
              </g>\
            </svg>";

          }
          else if(mode == 'vertical') {
            btn.className = 'vertical' + (options.invert ? ' invert' : ' normal');

            btn.innerHTML = "\
            <svg width='22' height='4' \
              style='display:block; margin:auto;'>\
              <g fill='#42526E' fill-rule='evenodd'>\
                <circle cx='2' cy='2' r='1'/>\
                <circle cx='8' cy='2' r='1'/>\
                <circle cx='14' cy='2' r='1'/>\
                <circle cx='20' cy='2' r='1'/>\
              </g>\
            </svg>";
          }
          else {
            btn.style.cursor = 'nwse-resize';
            btn.style.width = '15px';
            btn.style.height = '15px';
            btn.style.right = iconPosition[1] + 'px';
            btn.style.bottom = iconPosition[0] + 'px';
            btn.innerHTML = "\
            <svg>\
              <circle cx='10.5' cy='2.5' r='1' fill='#777777'></circle>\
              <circle cx='6.5' cy='6.5' r='1' fill='#777777'></circle>\
              <circle cx='2.5' cy='10.5' r='1' fill='#777777'></circle>\
            </svg>";
          }

          //bind resize function to button;
          btn.onmousedown = function($event) {
            $event.stopImmediatePropagation();
            position.x = $event.clientX;
            position.y = $event.clientY;
            dimension.width = element.prop('offsetWidth');
            dimension.height = element.prop('offsetHeight');
          	$document.bind('mousemove', mousemove);
          	$document.bind('mouseup', mouseup);
          	return false;
        	};
        	const mousemove = function($event) {
            scope.onResize();
            var deltaWidth = getValidWidth(calculateByInvert(dimension.width,(position.x - $event.clientX)));
          	var deltaHeight = getValidHeight(calculateByInvert(dimension.height, (position.y - $event.clientY)));

            var newDimensions = {};
            if(mode == 'horizontal') {
              newDimensions = {
              	width:  deltaWidth + 'px'
            	};
            }
            else if(mode == 'vertical') {
              newDimensions = {
              	height: deltaHeight + 'px'
            	};
            }
            else {
              newDimensions = {
              	width:  deltaWidth + 'px',
              	height: deltaHeight + 'px'
            	};
            }
          	element.css(newDimensions);
          	return false;
        	}
          const mouseup = function() {
         	  $document.unbind('mousemove', mousemove);
         		$document.unbind('mouseup', mouseup);
            scope.onChange({
              dimension: {
                width: element.prop('offsetWidth'),
                height: element.prop('offsetHeight')
              }
            });
        	};
          element.append(btn);
          
          const setBorderColor = (color) => {
            btn.style.setProperty('--borderColor', color);
          }

          angular.element(btn).bind('mouseover', () => setBorderColor(options.borderColor));
          angular.element(btn).bind('mouseout', () => setBorderColor('transparent'));

          const getValidWidth = (width) => {
            if (width < options.minWidth){
              return options.minWidth;
            }
            else if (width > options.maxWidth){
              return options.maxWidth;
            }

            return width;
          }

          const getValidHeight = (height) => {
            if (height < options.minHeight){
              return options.minHeight;
            }
            else if (height > options.maxHeight){
              return options.maxHeight;
            }

            return height;
          }

          const calculateByInvert = (val1,val2) => options.invert ? (val1 + val2) : (val1 - val2);
        }
    };
  }])
;
